import "./error.scss";

import Alert from "react-bootstrap/Alert";
import { useRouteError } from "react-router-dom";

import Navbar from "./navbar";

export default function ErrorPage() {
  const error = useRouteError() as any;

  return (
    <div className="error-div">
      <Navbar className="height-default"></Navbar>
      <Alert variant="warning" className="alert-component height-grow">
        <h1>{error.status}</h1>
        <p>{error.statusText}</p>
      </Alert>
    </div>
  );
}
