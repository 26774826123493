import "./App.scss";

import { Outlet } from "react-router";

import Navbar from "./navbar";

function App() {
  return (
    <div className="d-flex flex-column h-100 w-100 overflow-hidden">
      <Navbar></Navbar>
      <div className="app-content">
        <Outlet />
      </div>
    </div>
  );
}

export default App;
