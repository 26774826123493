import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "./home.scss";

function Home() {
  return (
    <div className="homecontainer">
      <div className="homecontent">
        <div className="container d-flex flex-column justify-content-center align-items-center">
        <p className="lead">An app to help find words for the <a href="https://www.nytimes.com/puzzles/spelling-bee">NY Times Spelling Bee</a>.</p>
          <Button
            variant="light"
            href="https://prod.lexihive.com"
            className="m-2 bigbutton"
            draggable="false"
          >
            <img src="logo512.png" alt="beehive" draggable="false"></img>
            <h1 className="">Click to start!</h1>
          </Button>
        </div>
      </div>
      <div className="homefooter text-center small">
        <Link to="/notices" className="text-dark">
          Notices
        </Link>
      </div>
    </div>
  );
}

export default Home;
