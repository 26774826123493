import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import App from "./App";
import ErrorPage from "./error";
import Home from "./home";
import Ack from "./notices/ack";
import Notices from "./notices/notices";
import Privacy from "./notices/privacy";
import Tos from "./notices/tos";
import reportWebVitals from "./reportWebVitals";
import Support from "./support";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/login",
        element: <Navigate to="https://prod.lexihive.com" />,
      },
      {
        path: "/notices",
        children: [
          {
            index: true,
            element: <Notices />,
          },
          {
            path: "acknowledgements",
            element: <Ack />,
          },
          {
            path: "tos",
            element: <Tos />,
          },
          {
            path: "privacy",
            element: <Privacy />,
          },
        ],
      },
      {
        path: "/support",
        element: <Support />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
