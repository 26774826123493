import "./notices.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Ack = () => {
  const [htmlContent, setHtmlContent] = useState("");
  useEffect(() => {
    fetch("/3plicense.html")
      .then((response) => response.text())
      .then((html) => {
        setHtmlContent(html);
      });
  }, []);

  return (
    <div className="d-flex flex-column noticescontainer">
      <div className="noticescontent m-2">
      <h1 className="m-2">Acknowledgements</h1>
        <p>Last Updated: May 25, 2023</p>
        <h2>Ngrams</h2>
        <p className="lead">
          Thank you{" "}
          <Link to="http://books.google.com/ngrams">
            Google Books Ngram Viewer
          </Link>{" "}
          for the Ngram data.
        </p>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </div>
    </div>
  );
};
export default Ack;
