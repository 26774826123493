import "./notices.scss";

const Tos = () => {
  return (
    <div className="d-flex flex-column noticescontainer">
      <div className="noticescontent m-2">
        <h1>Terms of Service</h1>
        <p>Last Updated: June 29, 2023</p>
        <p className="lead">
          By using the services provided by Newhouse LLC ("Company"), you are
          agreeing to these terms. Please read them carefully.
        </p>
        <h3>Children Under 13</h3>
        <p>
          If you are under 13, you must have your parent or guardian's
          permission to use our services. Please have them read this agreement
          with you.
        </p>
        <h3>Disclaimer of Warranties</h3>
        <p>
          Our services are provided "as is". The Company disclaims all
          warranties, whether express or implied, including the warranties of
          merchantability, fitness for a particular purpose and
          non-infringement.
        </p>
        <h3>Limitation of Liability</h3>
        <p>
          In no event will the Company be liable for any consequential,
          indirect, special, incidental or punitive damages, arising out of or
          relating to the services or the agreement.
        </p>
        <h3>Indemnification</h3>
        <p>
          You agree to indemnify, defend, and hold harmless the Company, its
          officers, directors, employees, and agents, from any claims, damages,
          liabilities, costs, or fees (including reasonable attorneys’ fees)
          arising from your use of the services or violation of this agreement.
        </p>
        <h3>Governing Law</h3>
        <p>
          The laws of Delaware, excluding its conflict of laws rules, govern
          this agreement and your use of the services. Your use of the services
          may also be subject to other local, state, national, or international
          laws.
        </p>
        <h3>Termination of Services</h3>
        <p>
          The Company reserves the right to terminate the services at any time,
          for any reason, and without notice. Your use of the services does not
          guarantee that they will continue to be available.
        </p>
        <h3>Sponsorship</h3>
        <p>
          Supporting the Company through sponsorships does not guarantee the continued
          operation of the services. Sponsorship is voluntary and is not a
          contract for service or an agreement for continued service.
        </p>
      </div>
    </div>
  );
};

export default Tos;
