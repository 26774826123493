import React from "react";
import { Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface NoticeProps {
  title: string;
  content: string;
  link: string;
}

const NoticeCard: React.FC<NoticeProps> = ({ title, content, link }) => {
  const navigate = useNavigate();
  return (
    <Card className="m-2" style={{ width: "300px", minHeight: "200px" }}>
      <Card.Header>{title}</Card.Header>
      <Card.Body>
        <Card.Text>{content}</Card.Text>
        <Button
          variant="secondary"
          onClick={() => {
            navigate(link);
          }}
        >
          Read Notice
        </Button>
      </Card.Body>
    </Card>
  );
};

const Notices = () => {
  const termsOfService = "Terms of using this service.";
  const privacy = "Practices concerning your personal information.";
  const acknowledgements =
    "Special thanks, dependency licenses, and copyright notices.";

  return (
    <div className="overflow-auto h-100">
      <h1 className="m-2">Notices</h1>
      <div className="row d-flex flex-wrap justify-content-start mx-2">
        <NoticeCard
          title="Terms of Service"
          link="/notices/tos"
          content={termsOfService}
        />
        <NoticeCard title="Privacy" link="/notices/privacy" content={privacy} />
        <NoticeCard
          title="Acknowledgements"
          link="/notices/acknowledgements"
          content={acknowledgements}
        />
      </div>
    </div>
  );
};

export default Notices;
